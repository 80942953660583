import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES, CODE} from "@contentful/rich-text-types"
import { Grid,Box,AspectImage,AspectRatio,Card,Button,Image,Text, Paragraph } from 'theme-ui'


function RonPost ({data}){
    const ronStory = data.allContentfulRonStories.edges[0].node
    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center">{children}</p>
    const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode:  {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      
        }
      }
    return(
        <Layout>
        <Box>
        <h1 dangerouslySetInnerHTML={{__html: ronStory.title}} />
            <img src={ronStory.thumbnail.fixed.src} />
            <p>
            {documentToReactComponents(JSON.parse(ronStory.story.raw),options)}
            </p>
          </Box>
        </Layout>
    )
}

export default RonPost

export const query = graphql`
    query($slug: String!){
        allContentfulRonStories(filter: { slug: { eq: $slug } }) {
            edges {
              node {
                contentful_id
                slug
                title
                story {
                  raw
                }
                thumbnail {
                  fixed {
                    src
                  }
                }
              }
            }
          }
    }
`